<template>
  <div>
    <div class="row m-t-n">
      <div class="col" style="width: 50%">
        <div>
          <erp-s-field
              view="lr"
              label="Data do leilão:"
              class="field-label-bold"
              label-width="150px"
          >
            {{lote.leilao.dataAbertura.date|formatDate('dd/MM/yyyy HH:mm:ss')}}
          </erp-s-field>
        </div>
        <!--<div class="m-t-sm">
          <erp-s-field
              view="lr"
              label="Lote:"
              class="field-label-bold"
              label-width="150px"
          >
            {{lote.numero}}
          </erp-s-field>
        </div>-->
        <div class="m-t-sm">
          <erp-s-field
              view="lr"
              label="Início do pregão:"
              class="field-label-bold"
              label-width="150px"
          >
            <span v-if="lote.historicoPregao && lote.historicoPregao.length && ultimoPregao.dataAbertura && ultimoPregao.dataAbertura.date">{{ultimoPregao.dataAbertura.date|formatDate('dd/MM/yyyy HH:mm:ss')}}</span>
            <span v-else>-</span>
          </erp-s-field>
        </div>
        <div class="m-t-sm">
          <erp-s-field
              view="lr"
              label="Término do pregão:"
              class="field-label-bold"
              label-width="150px"
          >
            <span v-if="lote.historicoPregao && lote.historicoPregao.length && ultimoPregao.dataEncerramento && ultimoPregao.dataEncerramento.date">{{ultimoPregao.dataEncerramento.date|formatDate('dd/MM/yyyy HH:mm:ss')}} ({{ultimoPregao.tempo}})</span>
            <span v-else>-</span>
          </erp-s-field>
        </div>

        <div class="m-t-md">
          <erp-s-field
              view="lr"
              label="Valor Mercado/Fipe:"
              class="field-label-bold"
              label-width="150px"
          >
            {{lote.valorMercado}}
          </erp-s-field>
        </div>
        <div class="m-t-sm">
          <erp-s-field
              view="lr"
              label="Valor Avaliação:"
              class="field-label-bold"
              label-width="150px"
          >
            {{lote.valorAvaliacao}}
          </erp-s-field>
        </div>
        <div class="m-t-sm">
          <erp-s-field
              view="lr"
              label="Valor Mínimo:"
              class="field-label-bold"
              label-width="150px"
          >
            {{lote.valorMinimo}}
          </erp-s-field>
        </div>
        <div class="m-t-sm">
          <erp-s-field
              view="lr"
              label="% Merc/Fipe sobre Avaliação:"
              class="field-label-bold"
              label-width="150px"
          >
            {{mercSobreAvaliacao}}%
          </erp-s-field>
        </div>
        <div class="m-t-sm">
          <erp-s-field
              view="lr"
              label="% Merc/Fipe sobre Mínimo:"
              class="field-label-bold"
              label-width="150px"
          >
            {{mercSobreMinimo}}%
          </erp-s-field>
        </div>
        <div class="m-t-sm">
          <erp-s-field
              view="lr"
              label="Valor Incremento:"
              class="field-label-bold"
              label-width="150px"
          >
            {{lote.valorIncremento}}
          </erp-s-field>
        </div>
        <div class="m-t-sm">
          <erp-s-field
              view="lr"
              label="Valor Inicial:"
              class="field-label-bold"
              label-width="150px"
          >
            {{lote.valorInicial}}
          </erp-s-field>
        </div>
        <div class="m-t-sm">
          <erp-s-field
              view="lr"
              label="Valor Meta:"
              class="field-label-bold"
              label-width="150px"
          >
            {{lote.valorMeta || 'Não definido'}}
          </erp-s-field>
        </div>
      </div>
      <div class="col" style="width: 50%">
        <div v-if="lote.arremate">
          <erp-label style="background-color: #F5F5F5" label="Dados sobre o arremate">
            <detalhe-venda :lote="lote" simple />
          </erp-label>
        </div>
        <detalhe-venda2 v-if="lote.arremate" :lote="lote" :diferenca-sobre-minimo="diferencaSobreMinimo" :impostos="impostos" :total-pagar="totalPagar" :total-pagar-leiloeiro="totalPagarLeiloeiro" :total-pago="totalPago" :saldo="saldo" class="m-t" />
      </div>
    </div>
    <div class="m-t">
      <p class="font-11 font-bold">Registros de pagamento</p>
      <registro-pagamento-lote class="m-t" :lote="lote" @update="() => $emit('update')" />
    </div>
  </div>
</template>

<script>
import {ErpSField, ErpLabel} from 'uloc-vue-plugin-erp'
import {porcentagemDiferenca} from '@/domain/leiloes/converters/calculos'
import {convertRealToMoney} from '@/utils/money'
import DetalheVenda from './DetalheVenda'
import DetalheVenda2 from './DetalheVenda2'
import RegistroPagamentoLote from './RegistroPagamentoLote'

export default {
  name: 'InfoPagamentoLote',
  props: ['lote', 'diferencaSobreMinimo', 'impostos', 'totalPagar', 'totalPagarLeiloeiro', 'totalPago', 'saldo'],
  components: {
    RegistroPagamentoLote,
    DetalheVenda2,
    DetalheVenda,
    ErpSField,
    ErpLabel
  },
  computed: {
    ultimoPregao () {
      return this.lote.historicoPregao[this.lote.historicoPregao.length - 1]
    },
    mercSobreAvaliacao () {
      return porcentagemDiferenca(convertRealToMoney(this.lote.valorAvaliacao), convertRealToMoney(this.lote.valorMercado))
    },
    mercSobreMinimo () {
      return porcentagemDiferenca(convertRealToMoney(this.lote.valorMinimo), convertRealToMoney(this.lote.valorMercado))
    }
  }
}
</script>

<style scoped>

</style>
