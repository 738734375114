<template>
  <div v-if="!loading" class="erp-window-panel">
    <div class="wrapper-md flex justify-between r-nav-right">
      <div class="navigation r-nav">
        <div class="tab" :class="{selected: tab === 'resumo'}" @click="tab = 'resumo'">Resumo</div>
        <div class="tab" :class="{selected: tab === 'fiscal'}"  @click="tab = 'fiscal'">Fiscal</div>
        <!--        <div class="tab">Financeiro</div>-->
      </div>
      <!--      <a class="link-black wrapper-xs m-t-n-xs cursor-pointer">
              <i class="fa-ellipsis-v fas app-icon u-icon" type="fa" aria-hidden="true"></i>
            </a>-->
    </div>
    <div v-if="tab === 'resumo'" class="bem-img">
      <a target="_blank" :href="bem.image ? bem.image.full.url : '#'"
         v-if="bem.image && bem.image.thumb"
         :style="'background-image: url('+ (bem.image.thumb.url || bem.image.thumb) +')'">
      </a>
    </div>
    <div v-if="tab === 'resumo'" class="content">
      <div class="bem-title">{{ bem.siteTitulo }}</div>
      <div class="bem-subtitle">{{ bem.siteSubtitulo }}</div>
      <table class="resumo-v-table font-avenir m-t-lg">
        <tbody>
        <tr>
          <td><span>ID Bem</span></td>
          <td class="text-right"><span>{{ bem.id }}</span></td>
        </tr>
        <tr>
          <td><span>Tipo</span></td>
          <td class="text-right "><span>{{ bem.tipoPai ? bem.tipoPai + ' - ' : '' }}{{ bem.tipo ? bem.tipo.nome : '-' }}</span></td>
        </tr>
        <tr v-if="bem.processoNumero">
          <td><span>Processo</span></td>
          <td class="text-right text-nowrap">
            <span class="">{{ bem.processoNumero }}</span>
          </td>
        </tr>
        <tr v-if="bem.processoExequente">
          <td><span>Exequente</span></td>
          <td class="text-right">
            <span class="no-wrap ellipsis" style="max-width: 160px">{{ bem.processoExequente }}</span>
          </td>
        </tr>
        <tr v-if="bem.processoExecutado">
          <td><span>Executado</span></td>
          <td class="text-right">
            <span class="no-wrap ellipsis" style="max-width: 160px">{{ bem.processoExecutado }}</span>
          </td>
        </tr>
        <tr>
          <td><span>Leilões</span></td>
          <td class="text-right"><span>{{ !bem.lotes ? 0 : bem.lotes.length }}</span></td>
        </tr>
<!--        <tr>
          <td><span>Plano</span></td>
          <td class="text-right"><a><span>Padrão</span></a><small><a class="m-l-xs"><i
              class="fa-edit fal app-icon u-icon" type="fa" aria-hidden="true"></i></a></small></td>
        </tr>-->
        </tbody>
      </table>

      <table v-if="loteContainer.ultimoLance" class="resumo-v-table font-avenir m-t-lg resumo-fin extrato_">
        <tbody>
        <tr>
          <td><span>Lance Atual</span></td>
          <td class="text-right"><span>{{ loteContainer.lanceAtual }}</span></td>
        </tr>
        <tr v-for="imposto in loteContainer.impostos">
          <td><span>{{ loteContainer.mountImpostoLabel(imposto.imposto) }}</span></td>
          <td class="text-right"><span>R$ {{ imposto.valor|moeda }}</span></td>
        </tr>
        </tbody>
      </table>
      <table v-if="loteContainer.ultimoLance" class="resumo-v-table font-avenir resumo-fin extrato">
        <tr>
          <td><span>Total Impostos/Taxas</span></td>
          <td class="text-right"><span>R$ {{ loteContainer.totalImposto|moeda }}</span></td>
        </tr>
        <tr>
          <td><span>Total a Pagar</span></td>
          <td class="text-right font-bold"><span>R$ {{ loteContainer.total|moeda }}</span></td>
        </tr>
        <tr v-if="lote.bem.comitente?.depositoDireto">
          <td><span>Total a Pagar Leiloeiro</span></td>
          <td class="text-right font-bold"><span>R$ {{ loteContainer.totalLeiloeiro|moeda }}</span></td>
        </tr>
        <tr>
          <td><span>Total pago</span></td>
          <td class="text-right font-bold text-primary"><span>R$ {{ loteContainer.totalPago|moeda }}</span></td>
        </tr>
        <tr>
          <td><span>Saldo devedor</span></td>
          <td class="text-right font-bold" :class="{'text-negative': loteContainer.saldo < 0, 'text-purple': loteContainer.saldo > 0}"><span>R$ {{ loteContainer.saldo|moeda }}</span> <small v-if="loteContainer.saldo > 0" class="font-10">a mais</small></td>
        </tr>
      </table>
      <div class="flex flex-center items-center">
        <div v-if="lote.status === 100 && lote.arremate && lote.arremate.status === statusPago" class="stamp is-approved">
          PAGO
        </div>
      </div>
    </div>
    <fiscal v-if="tab === 'fiscal'" />
  </div>
  <div v-else class="erp-window-panel text-center loading">
    <div class="wrapper-xl">
      <u-fake-loader width="20%" height="4px"/>
      <br>
      <br>
      <u-fake-loader width="100%" height="4px"/>
      <u-fake-loader width="100%" height="10px"/>
      <u-fake-loader width="100%" height="8px"/>
      <u-fake-loader width="100%" height="20px"/>
    </div>
  </div>
</template>

<script>
import {UFakeLoader} from "uloc-vue"
import {STATUS_PAGO} from "@/domain/leiloes/helpers/PagamentoStatus"
import Fiscal from "components/leiloes/components/include/lote/Fiscal"

export default {
  name: "LoteExtra",
  inject: ['loteContainer'],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tab: 'resumo'
    }
  },
  computed: {
    lote() {
      return this.loteContainer.lote
    },
    bem() {
      return this.lote.bem
    },
    statusPago () {
      return STATUS_PAGO
    }
  },
  mounted() {
    this.$el.parentNode.parentNode.parentNode.parentNode.prepend(this.$el)
  },
  beforeDestroy() {
    this.$el && this.$el.parentNode && this.$el.parentNode.parentNode.parentNode.parentNode && this.$el.parentNode.parentNode.parentNode.parentNode.contains(this.$el) && this.$el.parentNode.parentNode.parentNode.parentNode.removeChild(this.$el)
  },
  components: {
    UFakeLoader,
    Fiscal
  }
}
</script>
